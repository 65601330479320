<script setup lang="ts">
const appConfig = useAppConfig();
</script>

<template>
  <employees-card
    name="bernd-helm"
    show-contact
    show-executive-role
    show-primary-team
    :class="appConfig.projectManagerClasses"
  />
  <employees-card
    name="daniel-walter"
    show-contact
    show-executive-role
    show-primary-team
    :class="appConfig.projectManagerClasses"
  />
  <employees-card
    name="jens-bornschein"
    show-contact
    show-primary-team
    :class="appConfig.projectManagerClasses"
  />
  <employees-card
    name="nico-hoffmann"
    show-contact
    show-primary-team
    :class="appConfig.projectManagerClasses"
  />
  <employees-card
    name="robert-schwabe"
    show-contact
    show-primary-team
    :class="appConfig.projectManagerClasses"
  />
</template>
